<template>
  <div>
    <div class="template-main-box">
      <div class="template-tabs-box">
        <div>
          <div class="content-box">
            <div class="left-box">
              <div class="sousuo-box">
                <img src="@/assets/images/sousuo.png" alt="" />
              </div>
              <div class="tabs-box">
                <div
                  v-for="item in tabList"
                  :key="item.key"
                  :class="{
                    select: selectedTab == item.key,
                    'first-tab': item.key == '1',
                    disabled: item.disabled,
                  }"
                  @click="
                    () => {
                      if (!item.disabled) selectedTab = item.key;
                    }
                  "
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
            <div class="right-box">
              <div v-for="item in temList" :key="item.url">
                <div class="text-box">{{ item.text }}</div>
                <div class="img-box">
                  <img :src="item.url" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="fenye-box">
            <img src="@/assets/images/fenye.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from "../home/ContactUs";
export default {
  name: "template", // 开放能力

  components: {
    ContactUs,
  },

  data() {
    return {
      selectedTab: "1",
      tabList: [
        { key: "1", label: "为你推荐" },
        { key: "2", label: "外卖", disabled: true },
        { key: "3", label: "供应链", disabled: true },
        { key: "4", label: "商铺", disabled: true },
      ],
      temList: [
        {
          text: "高净值客户分布图",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/961bcf8eda2a538ccbc44104b2e85192.svg",
        },
        {
          text: "订单分析",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/97f1aa4cfc7ffcd35a0abdb6e5d3c89d.svg",
        },
        {
          text: "门店分析",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/bbd5789313994f3bba09c1daaeb0924c.svg",
        },
        {
          text: "视频监控地图",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/afaf1381ff2cc7f6c072c66557afe81c.svg",
        },
        {
          text: "充电桩分布",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/affb605fc21d832f5b3d763948e523cd.png",
        },
        // {text:'公交站分布图',url:'https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/dceeeb07c93f778a59adeb5c356e0c73.png'},
        {
          text: "商圈分析",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/a754d88d28669a5d98b6cd652670fb06.png",
        },
        {
          text: "客户分析",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/882daeccf8301d7f791c0086be8e3f0a.png",
        },
        {
          text: "高档小区分布",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/1a36090d75b1738bdae1f0da0d2836a7.png",
        },
        {
          text: "地铁站点分布",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/5a7dbf3da5fbcdac8b776e55ac645c58.svg",
        },
        {
          text: "竞品门店分析",
          url:
            "https://cdn3.codesign.qq.com/prototypes/2023/06/09/JG2mj237n8x606ZVKdM09/k3riddlzop0yntt5/20a6310bc1324041d729ac26caf73843.png",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
.template-main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > div {
    width: 1180px;
  }
  .template-tabs-box {
    width: 100%;
    padding: 80px 0;
    background-image: linear-gradient(180deg, #0272c91a 0%, #ffffff00 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 1180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-box {
        display: flex;
        width: 100%;
        .left-box {
          width: 157px;
          padding: 30px 20px;
          flex-shrink: 0;
          .sousuo-box {
            width: 117.75px;
            height: 35.43px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .tabs-box {
            margin-top: 19.96px;
            display: flex;
            flex-direction: column;
            gap: 9.84px;
            & > div {
              width: 117.75px;
              height: 35.43px;
              border-radius: 4px;
              cursor: pointer;
              font-family: HarmonyOS_Sans;
              font-weight: SC;
              font-size: 14px;
              color: #bbbec1;
              padding: 0 9.54px;
              display: flex;
              align-items: center;
            }
            .first-tab {
              margin-bottom: 9.96px;
              position: relative;
            }
            .first-tab::after {
              content: " ";
              width: 117px;
              height: 1px;
              background: #262a311a;
              position: absolute;
              bottom: -19.61px;
              left: 0;
            }
            .select {
              background: #ffffff;
              color: #242627;
            }
            .disabled {
              cursor: no-drop;
            }
          }
        }
        .right-box {
          padding: 30px 20px;
          flex-grow: 1;
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          & > div {
            cursor: pointer;
            width: 180px;
            height: 216px;
            background: #ffffff;
            border-radius: 4px;
            padding: 12.64px 9.83px 9.82px 9.84px;
            &:hover {
              box-shadow: 0 0 14px 0 #0000000f;
            }
            .text-box {
              margin-bottom: 12.63px;
              font-family: HarmonyOS_Sans;
              font-weight: 500;
              font-size: 14px;
              color: #242627;
            }
            .img-box {
              width: 161.33px;
              height: 166.91px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .fenye-box {
        width: 270px;
        height: 26px;
        margin: 30px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
