var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"template-main-box"},[_c('div',{staticClass:"template-tabs-box"},[_c('div',[_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"left-box"},[_vm._m(0),_c('div',{staticClass:"tabs-box"},_vm._l((_vm.tabList),function(item){return _c('div',{key:item.key,class:{
                  select: _vm.selectedTab == item.key,
                  'first-tab': item.key == '1',
                  disabled: item.disabled,
                },on:{"click":() => {
                    if (!item.disabled) _vm.selectedTab = item.key;
                  }}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]),_c('div',{staticClass:"right-box"},_vm._l((_vm.temList),function(item){return _c('div',{key:item.url},[_c('div',{staticClass:"text-box"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":item.url,"alt":""}})])])}),0)]),_vm._m(1)])])]),_c('contact-us')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sousuo-box"},[_c('img',{attrs:{"src":require("@/assets/images/sousuo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fenye-box"},[_c('img',{attrs:{"src":require("@/assets/images/fenye.png"),"alt":""}})])
}]

export { render, staticRenderFns }